import { useAnimation, motion } from "framer-motion"
import { useState, useMemo, useEffect } from "react"
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { Grid } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const text = [
    "To my darling Franny, without whom a poem about Franny would not be possible",
    ["I love the way you light the room",
        "Somehow, even while sleeping",],
    ["I float in the space above your face",
        "and try to hear your dreaming",],
    [`You leave for work. I'm left alone`,
        `Your cat now thinks we're besties`,],
    [`I give her some pets. I sit and reflect`,
        `on how your leave upsets me`,],
    [`I'm not upset because you left`,
        `as though you did it slyly`,],
    [`When work isn't close, the one I love most`,
        `is not right here beside me`,],
    [`I wonder if she ate enough`,
        `or has too busy a day`,],
    [`I have some resolve, but I hope that she calls`,
        `even if she has nothing to say`,],
    [`I want to make her snort and chuckle`,
        `on days she has it the worst`,],
    [`I'd play any fool and break every rule.`,
        `This feeling is truly a first`,],
    [`Don't want to miss a single day`,
        `I'll keep my brain absorbent`,],
    [`Because every word that I've ever heard`,
        `From you is the most important`,],
    [`You're smart as hell and wise to the words`,
        `Can you even see your charms?`,],
    [`The prettiest face, I never felt safe`,
        `Until I was held in your arms`,],
    [`The world can be a stressful place`,
        `That makes me scared and clammy`,],
    [`But one of the perks is Heaven's on earth`,
        `And she goes by the name of Franny`,],
    "I love you sweetheart",
]

const MainPage = () => {
    const [page, setPage] = useState(0)

    const controls = useAnimation()

    const nextPage = (e) => {
        e.preventDefault()
        setPage(prev => Math.min(prev + 1, text.length - 1))
    }

    const prevPage = (e) => {
        e.preventDefault()
        setPage(prev => Math.max(prev - 1, 0))
    }

    useEffect(() => {
        controls.start({
            opacity: 1
        })
    }, [])

    useEffect(() => {
        controls.start({
            opacity: [0, 1],
        })
    }, [page])

    const isOne = typeof (text[page]) === 'string'

    return <div style={{ marginTop: '1vw' }}>
        <Grid container justifyContent={"center"} direction="row">
            <Grid item onClick={prevPage} style={{ cursor: 'pointer' }}>
                <ArrowBackIos fontSize="large" />
            </Grid>
            <Grid item onClick={nextPage} style={{ cursor: 'pointer' }}>
                <ArrowForwardIos fontSize="large" />
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item xs={9}>
                <Grid container alignItems="center" direction="column" style={{
                    marginTop: '3vw'
                }}>
                    {isOne ? <>
                        <Grid item>
                            <motion.h1
                                style={{ fontFamily: 'EB Garamond' }}
                                initial={{ opacity: 0 }}
                                animate={controls}
                                transition={{ duration: 1 }}>
                                {text[page]}
                            </motion.h1>
                        </Grid>
                    </> :
                        <>
                            <Grid item>
                                <motion.h1
                                    style={{ fontFamily: 'EB Garamond' }}
                                    initial={{ opacity: 0 }}
                                    animate={controls}
                                    transition={{ duration: 1 }}>
                                    {text[page][0]}
                                </motion.h1>
                            </Grid>
                            <Grid item>
                                <motion.h1
                                    style={{ fontFamily: 'EB Garamond' }}
                                    initial={{ opacity: 0 }}
                                    animate={controls}
                                    transition={{ duration: 1 }}>
                                    {text[page][1]}
                                </motion.h1>
                            </Grid>
                        </>}
                    {page === text.length - 1 ? <motion.div
                        style={{ fontFamily: 'EB Garamond' }}
                        initial={{ opacity: 0 }}
                        animate={controls}
                        transition={{ duration: 1 }}
                    >
                        <FavoriteBorderIcon />
                    </motion.div> : null}
                </Grid>
            </Grid>
        </Grid>
    </div>
}

export default MainPage